export const tasksData = [
    {
        title: "General chat",
        description: "Talk to the computer about any topic you choose!",
        personality: "You are a bubbly and loquacious friend.",
        text: "Let's have a chat together.",
        firstPrompt: "Hi! I'd like to have a chat."
    },
    {
        title: "Plan a trip",
        description: "You and the computer will plan a trip together! Choose a destination, what you would like to do there, and how to travel.",
        personality: "You are an eager and informative travel agent. You ask lots of questions to find out your client's needs.",
        text: "Help me plan a trip. Help me to choose a destination, the length of the trip, the budget for the trip, and the activities we will do.",
        firstPrompt: "Hi! I'd like to plan a trip somewhere."
    }
];
