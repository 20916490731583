import './normalize.css';
import './App.css';
import React, {useState, useEffect} from "react";

import {tasksData} from './tasksData.js';

function App() {
    // useEffect(() => {
    //     getEngines();
    // }, []);

    const [input, setInput] = useState("");
    const currentModel = "text-davinci-003";
    // const server = "http://localhost:3080"
    const server = "https://macaronapi.stephenhefford.com:3080"

    // const [chatLog, setChatLog] = useState([{
    //     user: "gpt",
    //     message: "How can I help you?"
    // }, {
    //     user: "me",
    //     message: "I want to improve my English"
    // }]);
    const [chatLog, setChatLog] = useState([]);
    const [teacherLog, setTeacherLog] = useState("");
    const [selectedTask, setSelectedTask] = useState(tasksData[0]);

    function newTask() {
        setChatLog([]);
        setTeacherLog("");
    }

    function handleTaskChange (event) {
        // console.log(`Task change: ${event}`);
        console.log(event.target.value);
        let task = tasksData.filter(obj => {
            return obj.title === event.target.value;
        });
        setSelectedTask(task[0]);
        setChatLog([task[0].firstPrompt]);
    }

    async function handleSubmit(e) {
        e.preventDefault();
        let chatLogNew = [...chatLog, {user: "me", message: `${input}`}];
        let teacherQuery = "If this text is ungrammatical, explain how and provide a corrected text. Respond with 'great' or 'good job' if it does not need to be corrected: " + input;
        setInput("");
        setChatLog(chatLogNew);
        const messages = chatLogNew.map((message) => message.message).join("\n");
        console.log(`Messages: ${messages}`);
        console.log(`Teacher query: ${teacherQuery}`);

        const response = await fetch(server, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                message: selectedTask.personality + " " + selectedTask.text + "\n" + messages,
                currentModel: currentModel
            })
        });

        const teacherResponse = await fetch(server, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                message: teacherQuery,
                currentModel: currentModel
            })
        });

        const data = await response.json();
        const teacherData = await teacherResponse.json();

        setChatLog([...chatLogNew, {user: "gpt", message: `${data.message}`}]);
        setTeacherLog(teacherData.message);
        console.log(data.message);
        console.log(teacherData.message);
    }

    return (<div className="App">
        <aside className="sidemenu">
            <div className="sidemenu-newtask">
                <div className="newtasks-title">
                    <span>+</span>
                    New Task
                </div>
                <div className="newtasks-select">
                    <select value={selectedTask.title} onChange={handleTaskChange}>
                        {
                            tasksData.map((datum, key) => {
                                return (
                                    <option key={key} value={datum.title}>{datum.title}</option>
                                );
                            })
                        }
                    </select>
                </div>
                <div className="newtasks-description">
                    {selectedTask.description}
                </div>
                <div className="newtasks-button" onClick={newTask}>
                    Go!
                </div>
            </div>
            <div className="sidemenu-teacher">
                {teacherLog}
            </div>
        </aside>
        <section className="chatbox">
            <div className="chat-log">
                {
                    chatLog.map((message, index) => (
                        <ChatMessage key={index} message={message}/>
                    ))
                }
            </div>
            <div className="chat-input-holder">
                <form onSubmit={handleSubmit}>
                    <input
                        value={input}
                        onChange={(e) => setInput(e.target.value)}
                        className="chat-input-textarea"
                        placeholder="Type your message here">
                    </input>
                </form>
            </div>
        </section>
    </div>);
}

const ChatMessage = ({message}) => {
    return (
        <div className={`chat-message ${message.user === "gpt" && "chatgpt"}`}>
            <div className="chat-message-center">
                <div className={`avatar ${message.user === "gpt" && "chatgpt"}`}>
                    {message.user === "gpt" && "svg"}
                </div>
                <div className="message">
                    {message.message}
                </div>
            </div>
        </div>
    );
};



export default App;
